// * /////////////////////////////////////////////////////////////////////////////////////////////
// *
// * FileName     [ index.scss ]
// * PackageName  [ src/Courses/Course ]
// * Synopsis     [ Style for index.js ]
// * Author       [ Cheng-Hua Lu ]
// * Copyright    [ 2022 8 ]
// *
// * /////////////////////////////////////////////////////////////////////////////////////////////

@import '../../../index.scss';

.course-container {
    width: 100%;
    height: 100%;
    padding: 1% 0;
    color: $black-blue;

    .course-wrapper {
        background-color: #f3f3f3;
        border-radius: 15px;
        width: 95%;
        height: 80%;
        margin: auto;
        display: flex;
        padding: 10px;

        &:hover {
            background-color: white;
        }

        .content-wrapper {
            display: inline-block;
            width: 95%;
            margin: 0 auto;

            h2 {
                font-weight: bold;
                font-size: 28px;
                text-align: justify;
                margin: auto;
                line-height: 2;
            }

            .icon-list {
                display: flex;

                .semester {
                    display: flex;
                    color: $dark-gray;

                    .semester-icon {
                        display: inline-block;
                        font-size: 16px;
                        margin: auto;
                        padding-left: 10px;
                    }

                    .semester-text {
                        display: inline-block;
                        font-size: 16px;
                        padding-left: 10px;
                        padding-right: 10px;
                        margin: auto;
                        font-weight: 400;
                        // background-color: aquamarine;
                    }
                }

                .github-repo {
                    line-height: 2;
                    font-size: 18px;
                    border-radius: 10px;
                    background-color: $light-blue;
                    opacity: 0.7;
                    margin: 0;
                    color: $dark-blue;
                    text-decoration: none;

                    &:hover {
                        opacity: 1;
                    }

                    .github-icon {
                        padding-left: 10px;
                        padding-right: 10px;
                        font-size: 16px;
                        margin: auto;
                    }

                    .github-text {
                        font-weight: 400;
                        padding-left: 0px;
                        padding-right: 10px;
                    }
                }
            }

            .intro-block {
                font-size: 20px;
                text-align: justify;
                font-weight: 400;
                padding: 1% 0;
                // background-color: aqua;
                margin: auto;
            }

            .content-with-button {
                display: flex;
                width: 100%;
                margin: auto;
                padding: auto;

                button {
                    display: inline-block;
                    width: 120px;
                    height: 30px;
                    margin: auto;
                    right: 0;
                    bottom: 0;
                    font-size: 16px;
                    font-weight: 400;
                    border: transparent;
                    background-color: $dark-blue;
                    opacity: 0.8;
                    color: $light-blue;
                    border-radius: 5px;

                    &:hover {
                        opacity: 1;
                    }
                }

                .content-list {
                    display: inline-block;
                    padding: 0;
                    margin: 0;
                    width: 90%;

                    ul {
                        padding: 0;
                        margin: 0;
                        padding-left: 3%;
                        list-style-type: decimal;
                        list-style-position: outside;

                        li {
                            font-size: 18px;
                            color: $dark-gray;
                        }
                    }
                }
            }

            .link-button {
                text-align: right;

                a {
                    width: 200px;
                    height: 30px;
                    text-decoration: none;
                    font-size: 16px;
                    font-weight: 400;
                    color: $dark-blue;

                    &:hover {
                        font-weight: 700;
                    }
                }
            }
        }
    }
}