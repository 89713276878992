// * /////////////////////////////////////////////////////////////////////////////////////////////
// *
// * FileName     [ index.scss ]
// * PackageName  [ src/Home/NewsAwards/Item ]
// * Synopsis     [ Style for index.js ]
// * Author       [ Cheng-Hua Lu ]
// * Copyright    [ 2022 8 ]
// *
// * /////////////////////////////////////////////////////////////////////////////////////////////

@import '../../../../index.scss';

.item-container {
    width: 100%;
    height: 100%;
    margin-bottom: 2%;

    .wrapper {
        width: 100%;
        height: 100px;
        margin: auto;

        display: flex;
        color: $dark-blue;
        justify-content: space-between;

        .left-col {
            background-color: $dark-blue;
            display: inline-block;
            width: 15%;
            color: $light-blue;
            font-size: 20px;
            font-weight: 400;
            text-align: center;
            line-height: 1;
            vertical-align: middle;
            padding-top: 3%;

            p {
                margin: auto;
            }
        }

        .right-col {
            display: inline-block;
            width: 80%;
            margin: 0 auto;

            h3 {
                font-weight: bold;
                font-size: 24px;
                text-align: justify;
            }

            .attendants-list {
                font-size: 20px;
                font-weight: 400;
                color: $dark-gray;
                margin: 0;
                padding: 0;
            }
        }
    }
}