// * /////////////////////////////////////////////////////////////////////////////////////////////
// *
// * FileName     [ index.scss ]
// * PackageName  [ src/Members/Group ]
// * Synopsis     [ Style for index.js ]
// * Author       [ Cheng-Hua Lu ]
// * Copyright    [ 2022 8 ]
// *
// * /////////////////////////////////////////////////////////////////////////////////////////////

@import '../../../index.scss';

.group-container {
    width: 100%;
    height: 100%;

    .wrapper {
        margin: auto;
        width: 90%;

        h1 {
            font-size: $subtitle-font-size;
            color: $gray-blue;
        }
    }
}