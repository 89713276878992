// * /////////////////////////////////////////////////////////////////////////////////////////////
// *
// * FileName     [ index.scss ]
// * PackageName  [ src/Home/NewsAwards ]
// * Synopsis     [ Style for index.js ]
// * Author       [ Cheng-Hua Lu ]
// * Copyright    [ 2022 8 ]
// *
// * /////////////////////////////////////////////////////////////////////////////////////////////

@import '../../../index.scss';

.news-container {
    background-color: $light-gray;
    opacity: 0.8;
    width: 100%;

    .wrapper {
        margin: 3% 20%;
        width: 60%;
        height: auto;
        min-height: 10vh;

        h1 {
            font-family: 'Coolvetica';
            font-size: $subtitle-font-size;
            color: $dark-blue;
            font-weight: 500;
            padding: 3% 0%;

            .text-animate {
                &:hover {
                    color: $black-blue;
                    font-weight: 700;
                }
            }
        }

        .content {
            padding-top: 2%;
            padding-bottom: 2%;
        }
    }
}