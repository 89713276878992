// * /////////////////////////////////////////////////////////////////////////////////////////////
// *
// * FileName     [ index.scss ]
// * PackageName  [ src/Home/Logo ]
// * Synopsis     [ Style for index.js ]
// * Author       [ Cheng-Hua Lu ]
// * Copyright    [ 2022 8 ]
// *
// * /////////////////////////////////////////////////////////////////////////////////////////////

@import '../../../index.scss';

.logo-container {
    width: 100%;
    height: 100%;
    min-height: 80vh;

    .wrapper {
        width: 100%;
        min-height: 80vh;
        padding: 5% 10%;

        .chip_img {
            position: absolute;
            display: block;
            z-index: 1;
            opacity: 1;
            width: 19%;
            margin-left: 27.3%;
            margin-top: 8%;
        }

        .bug_img {
            position: absolute;
            display: block;
            z-index: 2;
            width: 6%;
            margin-left: 29.6%;
            margin-top: 11.3%;
        }

        .maginfier_img {
            position: absolute;
            display: block;
            z-index: 3;
            width: 14.3%;
            margin-left: 24.6%;
            margin-top: 5.4%;
        }

        .ntugiee_img {
            position: absolute;
            display: block;
            z-index: 4;
            width: 27%;
            margin-left: 32.8%;
            margin-top: 7.9%;
        }

        .dvlab_img {
            position: absolute;
            display: block;
            z-index: 5;
            width: 39%;
            height: auto;
            margin-left: 20.2%;
            margin-top: 27%;
        }

        .description_img {
            position: absolute;
            display: block;
            z-index: 6;
            width: 60%;
            height: auto;
            margin-left: 10%;
            margin-top: 33%;
        }
    }
}