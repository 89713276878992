// * /////////////////////////////////////////////////////////////////////////////////////////////
// *
// * FileName     [ index.scss ]
// * PackageName  [ src/About/Slide ]
// * Synopsis     [ Style for index.js ]
// * Author       [ Cheng-Hua Lu ]
// * Copyright    [ 2022 8 ]
// *
// * /////////////////////////////////////////////////////////////////////////////////////////////

@import '../../../index.scss';

.slide-container {
    display: table;
    width: 100%;
    height: 100%;
    animation: forwards;
    margin: auto;

    .wrapper {
        width: 100%;
        margin: auto 0;

        img{
            display: block;
            width: 80%;
            height: 80%;
            margin:auto;
            border-radius: 25px;
        }

        // .text-col {
        //     display: inline-block;
        //     width: 40%;
        //     height: 100%;
        //     margin: auto;

        //     .text-wrapper {
        //         display: table;
        //         width: 90%;
        //         margin: auto;

        //         span {
        //             font-size: 24px;
        //             color: $light-blue;
        //             display: table-cell;
        //             font-family: 'Helvetica Neue';

        //             font-weight: 400;
        //             vertical-align: middle;
        //             text-align: justify;
        //         }
        //     }
        // }

        // .image-col {
        //     display: inline-block;
        //     width: 60%;

        //     img {
        //         width: 100%;
        //         height: auto;
        //         border-radius: 25px;
        //     }
        // }
    }
}