// * /////////////////////////////////////////////////////////////////////////////////////////////
// *
// * FileName     [ index.scss ]
// * PackageName  [ src/About ]
// * Synopsis     [ Style for index.js ]
// * Author       [ Cheng-Hua Lu ]
// * Copyright    [ 2022 8 ]
// *
// * /////////////////////////////////////////////////////////////////////////////////////////////

@import "../../index.scss";
@import "animate.css";

.about-lab-container {
    background-image: linear-gradient(0deg, $light-blue, 15%, $dark-blue);
    width: 100%;
    height: max-content;
    padding-bottom: 3%;

    .wrapper {
        // margin: 0 10%;
        width: 100%;
        height: max-content;

        h1 {
            width: 80%;
            margin: 0 10%;
            font-family: "Coolvetica";
            font-size: $subtitle-font-size;
            color: $light-yellow;
            font-weight: 500;
            padding: 3% 0%;

            .text-animate {
                &:hover {
                    color: $dark-yellow;
                }
            }
        }

        .about-content {
            width: 100%;

            .introduction {
                display: flex;
                justify-content: space-between;
                margin: 0 10%;
                width: 80%;

                // background-color: red;

                .left-col {
                    display: inline-block;
                    width: 55%;

                    // background-color: paleturquoise;
                    p {
                        font-size: 22px;
                        font-weight: 400;
                        text-align: justify;
                        text-indent: 2em;
                        color: white;
                    }
                }

                .right-col {
                    display: inline-block;
                    width: 40%;
                    // background-color: papayawhip;
                    margin: auto 0;

                    img {
                        width: 100%;
                        border-radius: 25px;
                        margin: auto;
                        padding: auto;
                    }
                }
            }

            .lab-culture {
                height: max-content;
                width: 80%;
                margin: 3% 10%;
                padding: 0;
                span{
                    font-family: "Coolvetica";
                    font-size: $subtitle-font-size;
                    color: $light-yellow;
                    font-weight: 500;
                }
                .gallery {
                    width: 55%;
                    height: 100%;
                    margin: 3% auto;
                    // background-color: paleturquoise;
                    // vertical-align: middle;

                    // .photo-container{
                    //     width: 100%;
                    //     height: auto;
                    //     background-color: pink;
                        
                        
                    //     img{
                    //         display: block;
                    //         width: 80%;
                    //         height: auto;
                    //         margin:auto;
                    //         border-radius: 25px;
                    //     }

                    // }
                    
                }
            }
        }
    }
}

@for $i from 1 through 100 {
    .text-animate._#{$i} {
      animation-delay: #{$i / 20}s;
    }
}