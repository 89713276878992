// * /////////////////////////////////////////////////////////////////////////////////////////////
// *
// * FileName     [ App.scss ]
// * PackageName  [ src ]
// * Synopsis     [ Style for App.js ]
// * Author       [ Cheng-Hua Lu ]
// * Copyright    [ 2022 8 ]
// * 
// * /////////////////////////////////////////////////////////////////////////////////////////////

@import 'animate.css';
@import '~loaders.css/src/animations/pacman.scss';
@import '~loaders.css/src/animations/line-scale.scss';
@import "~slick-carousel/slick/slick-theme.css";
@import "~slick-carousel/slick/slick.css"; 

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../public/assets/fonts/helvetica-neu.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'La Belle Aurore';
  src: url('../public/assets/fonts/LaBelleAurore.woff2') format('woff2'),
    url('../public/assets/fonts/LaBelleAurore.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Coolvetica';
  src: url('../public/assets/fonts/CoolveticaRg-Regular.woff2') format('woff2'),
    url('../public/assets/fonts/CoolveticaRg-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

input,
textarea {
  font-family: 'Helvetica Neue', sans-serif;
}

.loader-hidden {
  display: none;
}

.loader-active {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  animation: fadeOut 1s 1s;
  animation-fill-mode: forwards;
}