// * /////////////////////////////////////////////////////////////////////////////////////////////
// *
// * FileName     [ index.scss ]
// * PackageName  [ src/NavBar ]
// * Synopsis     [ Style for index.js ]
// * Author       [ Cheng-Hua Lu ]
// * Copyright    [ 2022 8 ]
// *
// * /////////////////////////////////////////////////////////////////////////////////////////////

@import '../../index.scss';

.navbar-container {
    overflow: hidden;
    position: fixed;
    top: 0;
    font-family: 'Helvetica Neue';
    background-color: $black-blue;
    padding: 0px;
    height: 120px;
    width: 100%;

    .navbar-brand {
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 50px;
        padding-right: 50px;
        margin-top: 0;
        background-color: $black-blue;

        img {
            width: 120px;
            height: auto;
        }
    }

    .navbar-toggler {
        color: $light-gray;
        font-size: 24px;
        border-width: 1px;
        margin: 0px;
        line-height: 0;
        background-color: $black-blue;

        &:focus {
            text-decoration: none;
            outline: 0;
            box-shadow: 0 0 0 0;
        }

        &:hover {
            opacity: 0.8;
            border: rgba($color: $light-gray, $alpha: 0.8) 1px solid;
        }

        .nav-icon {
            color: $light-gray;
            opacity: 0.5;

            &:hover {
                opacity: 0.8;
            }
        }
    }
}

#navbarToggler {
    overflow: hidden;
    position: relative;

    ul {
        padding: 0px;
        margin: 0px;

        li {
            list-style-type: none;
            float: left;
            width: 200px;
            text-align: center;

            .nav-link {
                color: $light-gray;
                text-decoration: none;
                text-transform: uppercase;
                font-size: 20px;
                display: block;
                padding: 20px 20px;
                transition-duration: 0.6s;
                transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
                position: relative;
                justify-content: center;
                text-align: center;
                margin: auto;
                opacity: 0.5;

                &:hover {
                    opacity: 0.8;
                    color: $light-yellow;
                }

                .nav-icon {
                    display: block;
                    margin: auto;
                    font-size: 24px;
                }

                .nav-text {
                    display: block;
                    font-size: 16px;
                    padding-top: 11px;
                    font-weight: 400;
                }
            }

            :active {
                .nav-link {
                    color: #000000;
                    background-color: transparent;
                    transition: all 0.7s;
                }
            }
        }
    }
}