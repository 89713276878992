// * /////////////////////////////////////////////////////////////////////////////////////////////
// *
// * FileName     [ index.scss ]
// * PackageName  [ src/About/Gallery ]
// * Synopsis     [ Style for index.js ]
// * Author       [ Cheng-Hua Lu ]
// * Copyright    [ 2022 8 ]
// *
// * /////////////////////////////////////////////////////////////////////////////////////////////

@import '../../../index.scss';

.gallery-container {
    width: 100%;
    background-color: $light-blue;

    .wrapper {
        .a-row {
            display: flex;

            .left-col {
                display: inline-block;
                width: 65%;
                height: 100%;
                margin: auto;

                .content {
                    font-family: 'Helvetica Neue';
                    color: $dark-blue;
                    margin: 5% 15%;

                    h3 {
                        font-size: 36px;
                        // text-transform: uppercase;
                        margin: auto;
                    }

                    p {
                        line-height: 1.5;
                        font-size: 20px;
                    }
                }
            }

            .right-col {
                display: inline-block;
                width: 35%;

                img {
                    width: 100%;
                }
            }
        }
    }
}