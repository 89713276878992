// * /////////////////////////////////////////////////////////////////////////////////////////////
// *
// * FileName     [ index.scss ]
// * PackageName  [ src/Members/MemBio ]
// * Synopsis     [ Style for index.js ]
// * Author       [ Cheng-Hua Lu ]
// * Copyright    [ 2022 8 ]
// *
// * /////////////////////////////////////////////////////////////////////////////////////////////

@import '../../../index.scss';

.membio-container {
    background-image: linear-gradient(53deg, $gray-blue, 35%, $dark-blue);
    width: 100%;
    height: max-content;
    padding-bottom: 3%;
    color: white;

    .wrapper {
        margin: 0 10%;
        width: 80%;

        .content {
            width: 100%;
            height: auto;
            min-height: 50vh;

            .content-info {
                display: flex;
                width: 100%;
                height: auto;
                min-height: 70vh;
                padding-top: 8%;
                margin: auto;
                justify-content: space-between;

                .left-col {
                    display: inline-block;
                    width: 30%;
                    height: 100%;

                    .img-container {
                        display: flex;
                        margin: 0 auto;
                        justify-content: center;
                        height: 100%;

                        img {
                            border-radius: 50%;
                            width: 75%;
                            height: 100%;
                        }
                    }

                    h3 {
                        font-size: $subtitle-font-size;
                        text-align: center;
                        padding: 10% 0 0;
                    }

                    h6 {
                        font-size: 20px;
                        color: $light-gray;
                        text-align: center;
                    }

                    .media-list {
                        width: 80%;
                        margin: auto;

                        .media-item {
                            display: flex;

                            .media-icon {
                                font-size: 30px;
                                padding-right: 20px;

                                color: $light-gray;
                            }

                            a {
                                font-size: 16px;
                                margin-top: auto;
                                margin-bottom: auto;
                                color: $light-gray;
                                text-decoration: none;

                                &:hover {
                                    font-weight: 400;
                                    color: white;
                                }
                            }
                        }
                    }
                }

                .right-col {
                    display: inline-block;
                    width: 65%;
                    font-size: 36px;
                    color: white;

                    .item-block {
                        text-align: justify;
                        padding-bottom: 5%;

                        h3 {
                            font-size: $subtitle-font-size;
                            font-weight: 700;
                        }

                        p {
                            font-size: 20px;
                            padding-top: 0;
                            padding-bottom: 2%;
                            margin: auto;
                        }

                        ul {
                            margin: 0;
                            padding: 0 5%;

                            li {
                                font-size: 20px;
                                padding: 0;
                                line-height: 2;
                            }
                        }
                    }
                }
            }

            .education {
                text-align: justify;
                padding-top: 5%;
                padding-bottom: 3%;

                h3 {
                    font-size: $subtitle-font-size;
                    font-weight: 700;
                }

                .edu-list {
                    border-top: 0.5px $middle-gray solid;
                    border-bottom: 0.5px $middle-gray solid;

                    .edu-item {
                        display: flex;
                        font-size: 20px;
                        border-top: 0.5px $middle-gray solid;
                        border-bottom: 0.5px $middle-gray solid;
                        padding-top: 10px;
                        padding-bottom: 10px;

                        .edu-subitem {
                            display: inline-block;
                            padding-right: 50px;
                        }
                    }
                }
            }

            .publications {
                text-align: justify;
                padding-top: 3%;
                padding-bottom: 5%;

                h3 {
                    font-size: $subtitle-font-size;
                    font-weight: 700;
                }

                .pub-list {
                    .pub-item {
                        border-bottom: 0.5px $middle-gray solid;
                        font-size: 20px;
                        padding-top: 10px;
                        padding-bottom: 10px;

                        a {
                            color: $light-gray;

                            &:hover {
                                color: white;
                            }
                        }

                        span {
                            font-size: 16px;
                            color: $light-gray;
                        }

                        .institution {
                            font-size: 14px;
                            font-style: italic;
                        }
                    }
                }
            }
        }
    }
}