// * /////////////////////////////////////////////////////////////////////////////////////////////
// *
// * FileName     [ index.scss ]
// * PackageName  [ src ]
// * Synopsis     [ Style for index.js ]
// * Author       [ Cheng-Hua Lu ]
// * Copyright    [ 2022 8 ]
// * 
// * /////////////////////////////////////////////////////////////////////////////////////////////

$dark-yellow: #ffd700;
$light-yellow:#fcffcc;
$dark-green: #3c5842;
$light-blue: #bcdddf;
$dark-blue: #1f3751;
$black-blue: #14263a;
$gray-blue: #6daaaf;
$dark-gray: #777777;
$middle-gray: #aaaaaa;
$light-gray: #dddddd;
$title-font-size: 60px;
$subtitle-font-size: 44px;
$subsubtitle-font-size: 32px;

html {
  font-size: 62.5px;
}

body {
  margin: 0;
  font: 300 11px/1.4 'Helvetica Neue', 'sans-serif';
  color: $light-blue;
  background: $dark-blue;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

