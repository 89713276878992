// * /////////////////////////////////////////////////////////////////////////////////////////////
// *
// * FileName     [ index.scss ]
// * PackageName  [ src/Courses ]
// * Synopsis     [ Style for index.js ]
// * Author       [ Cheng-Hua Lu ]
// * Copyright    [ 2022 8 ]
// *
// * /////////////////////////////////////////////////////////////////////////////////////////////

@import '../../index.scss';

.courses-container {
    background-image: linear-gradient(36deg, $dark-blue 30%, $light-blue);
    width: 100%;
    height: max-content;
    padding-bottom: 3%;

    .wrapper {
        margin: 0 10%;
        width: 80%;
        height: max-content;

        h1 {
            font-family: 'Coolvetica';
            font-size: $title-font-size;
            color: $light-yellow;
            font-weight: 500;
            padding: 3% 0%;

            .text-animate {
                &:hover {
                    color: $dark-yellow;
                }
            }
        }

        .courses-list {
            width: 100%;
            justify-content: space-between;
        }
    }
}