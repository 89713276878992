// * /////////////////////////////////////////////////////////////////////////////////////////////
// *
// * FileName     [ index.scss ]
// * PackageName  [ src/Publications/Pub ]
// * Synopsis     [ Style for index.js ]
// * Author       [ Cheng-Hua Lu ]
// * Copyright    [ 2022 8 ]
// *
// * /////////////////////////////////////////////////////////////////////////////////////////////

@import '../../../index.scss';

.pub-container {
    width: 100%;
    height: 100%;
    padding: 1% 0;
    color: $black-blue;

    .wrapper {
        background-color: #f3f3f3;
        box-shadow: 0px 0px 5px 5px #f3f3f3;
        border-radius: 15px;
        width: 95%;
        height: 80%;
        margin: auto;
        display: flex;
        padding: 10px;

        &:hover {
            box-shadow: 0 0 0px 0px white;
            background-color: white;
        }

        .img-block {
            margin: auto;

            img {
                height: auto;
                width: 95%;
                margin: auto;
                justify-content: center;
                display: block;
            }
        }

        .text-block {
            display: inline-block;
            margin: 0 auto;

            h2 {
                font-weight: bold;
                font-size: 22px;
                text-align: justify;
            }

            .author-list {
                font-size: 20px;
                font-weight: 400;
            }

            .date {
                font-size: 18px;
                font-weight: 400;
                color: $dark-gray;
                font-style: italic;
            }

            .abstract {
                font-size: 16px;
                text-align: justify;
                font-weight: 400;
                padding: 1% 0;
            }

            .keywords-list {
                display: flex;

                .keyword {
                    display: inline-block;
                    background-color: $light-blue;
                    font-size: 15px;
                    border-radius: 5px;
                    padding: 5px 10px;
                    margin-right: 10px;
                    font-weight: 400;
                }
            }

            .link-button {
                text-align: right;

                a {
                    padding: 5px;
                    width: 200px;
                    height: 30px;
                    text-decoration: none;
                    font-size: 16px;
                    font-weight: 400;
                    color: $dark-blue;

                    &:hover {
                        font-weight: 700;
                    }
                }
            }
        }
    }
}